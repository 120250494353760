import React, {useCallback, useState} from 'react';
import {ThemeProvider} from '@mui/material';
import {StylesProvider} from '@mui/styles';
import {AISCacheService} from 'src/commons/services/cacheService';
import {themeCreator} from './base';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ThemeContext = React.createContext((themeName: string): void => {});

function ThemeProviderWrapper(props) {
  const curThemeName = AISCacheService.getTheme() || 'AISDefaultTheme';
  const [themeName, _setThemeName] = useState(curThemeName);
  const theme = themeCreator(themeName);

  const setThemeName = useCallback((themeName: string): void => {
    localStorage.setItem('AIS-appTheme', themeName);
    _setThemeName(themeName);
  }, []);

  return (
    <StylesProvider injectFirst>
      <ThemeContext.Provider value={setThemeName}>
        <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
      </ThemeContext.Provider>
    </StylesProvider>
  );
}

export default ThemeProviderWrapper;
