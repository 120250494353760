import { MenuItem, TextField } from "@mui/material";
import React, {  useState } from "react";
import { PropsFieldSelect } from "src/commons/models/commons";

type PropsInputFieldSelect = {
    field: PropsFieldSelect
    onChange ?: Function
    fullWidth ?:boolean
};

function InputSelect({field, onChange, fullWidth = true}: PropsInputFieldSelect) {
  const [currentField, setField] = useState(field);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event.target.value);
    }
    currentField.valueChange(event.target.value);
    setField({...currentField, value: event.target.value});
  };

  return (
    <TextField
      id={field.id}
      sx={field.sx ? field.sx : {}}
      label={field.label}
      select
      defaultValue={field.defaultValue ? field.defaultValue : ''}
      error={field.invalid}
      required={field.required}
      size={field.size ? field.size : 'medium'}
      variant={field.variant ? field.variant : 'outlined'}
      fullWidth={fullWidth}
      disabled={field.disabled}
      value={field.value}
      helperText={field.invalid ? field.errorText : ''}
      autoFocus={field?.autoFocus || false}
      onChange={handleChange}
    >
      {field.options &&
        field.options.map(option => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
    </TextField>
  );
}

export default InputSelect;