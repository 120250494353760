import { VisibilityOff, Visibility } from "@mui/icons-material";
import { InputAdornment, IconButton, TextField } from "@mui/material";
import React, {useState} from 'react';
import { PropsField } from "src/commons/models/commons";

type PropsInputFieldText = {
    field: PropsField
};

function InputPassword({field}: PropsInputFieldText) {
  const [currentField, setField] = useState(field);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    currentField.valueChange(event.target.value);
    setField({...currentField, value: event.target.value});
  };

  return (
    <TextField
      required={field.required}
      error={field.invalid}
      type={showPassword ? 'text' : 'password'}
      id={field.id}
      name={field.id}
      label={field.label}
      fullWidth
      autoComplete="off"
      size={field.size ? field.size : 'medium'}
      variant={field.variant ? field.variant : 'outlined'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      value={field.value}
      onChange={handleChange}
      helperText={field.invalid ? field.errorText : ''}
      onKeyDown={e => {
        if (field.keyDown) {
          field.keyDown(e);
        }
      }}
      autoFocus={field?.autoFocus || false}
    />
  );
}

export default InputPassword;