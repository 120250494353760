/* eslint-disable no-restricted-syntax */
import {useReducer, useState} from 'react';
import {User} from './user';

export interface APIResponse {
  nbResults: number;
  status: number;
  errors?: Array<any>;
  results?: Array<any>;
}

export interface TokenSession {
  token: string;
  user: User;
  configuration: Configuration;
}

export interface SelectOption {
  id: string | number;
  name: string;
  dummy_value?: any;
}

export interface RadioGroup {
  [options: string]: string;
}

export interface Configuration {
  selectGenderOptions?: Array<any>;
  selectProfileOptions?: Array<any>;
  selectTitles?: Array<any>;
  customer?: any;
  features?: {listFeatures: Array<Feature>; appSection: Array<FeaturesSection>};
}

export interface Profile {
  id: number;
  name: string;
  CustomerId: number;
  featuresLinked: Array<Feature>;
}

export interface Feature {
  id: number;
  type: string;
  name: string;
  applicationSection: string;
  value?: boolean;
  field?: any;
}

export interface FeaturesSection {
  id?: number;
  name?: string;
  expanded?: boolean;
  listFeatures?: Array<Feature>;
}

export interface PropsInput {
  field: PropsField;
}
export interface PropsInputSwitch {
  field: PropsFieldSwitch;
}
export interface PropsInputSelect {
  field: PropsFieldSelect;
}

export interface PropsField {
  id: string;
  label?: string;
  value: any;
  invalid?: boolean;
  required?: boolean;
  defaultValue?: any;
  errorText?: string;
  valueChange: Function;
  clearOnInit?: boolean;
  disabled?: boolean;
  maxLength?: number;
  size?: 'small' | 'medium';
  variant?: 'filled' | 'outlined' | 'standard';
  upperCase?: boolean;
  sx?: any;
  keyDown?: Function;
  autoFocus?: boolean;
}

export interface PropsFieldColor extends PropsField {
  hideColorPicker?: boolean;
}

export interface PropsFieldMask extends PropsField {
  mask: string;
  formatChars: any;
}

export interface PropsFieldSelect extends PropsField {
  options: Array<SelectOption>;
}
export interface PropsRadioGroup extends PropsField {
  default: string;
  options: RadioGroup;
}

export interface PropsFieldSwitch extends PropsField {
  labelOn?: string;
  labelOff?: string;
}

export interface PropsFieldDate extends PropsField {}

export interface Form {
  [key: string]: any;
}

export interface FormTmp extends Form {
  [key: string]: any;
}

export interface FormBase extends Form {
  [key: string]: any;
}

export interface FormEditUser extends Form {
  login: PropsField;
  email: PropsField;
  station: PropsField;
  firstName: PropsField;
  title: PropsField;
  profile: PropsFieldSelect;
  title2: PropsFieldSelect;
  password: PropsField;
  confirm: PropsField;
  mobile: PropsField;
  gender: PropsFieldSelect;
  enabled: PropsFieldSwitch;
  fax: PropsField;
}

export interface FormEditCustomerInterop extends Form {
  sort?: PropsField;
  tei?: PropsFieldMask;
  description?: PropsField;
  scope: PropsFieldSelect;
  type: PropsFieldSelect;
  remark?: PropsField;
  parameter?: PropsField;
  mandatory: PropsFieldSelect;
  defaultValue?: PropsField;
  filteringFields?: PropsFieldSelect;
  FeatureId?: PropsField;
}

export interface FormEditCustomerInteropCustom extends Form {
  keyboardType?: PropsFieldSelect;
  autoCapitalize?: PropsFieldSelect;
  minLength?: PropsField;
  maxLength?: PropsField;
  placeholder?: PropsField;
  barCode?: PropsFieldSwitch;
  pattern?: PropsFieldSelect;
}

export interface FormUploadDocument extends Form {
  is_revision: PropsFieldSwitch;
  cmm_number: PropsField;
  revision: PropsField;
  equipment: PropsFieldSelect;
  cmm_oem: PropsField;
  aircraft_type: PropsField;
  aircraft_registration: PropsField;
  comment: PropsField;
  cmm_file: PropsField;
  lopa_file: PropsField;
  optional_file?: PropsField;
}

export interface FormNotification extends Form {
  title: PropsField;
  subtitle: PropsField;
  date_send: PropsFieldDate;
  body: PropsField;
}

export interface FormDeviceStatus extends Form {
  title: PropsField;
  subtitle: PropsField;
  date_send: PropsFieldDate;
  body: PropsField;
}

export interface FormEditProfile extends Form {
  name: PropsField;
}

export interface FormEditProfileSelector extends Form {
  [name: string]: PropsFieldSwitch;
}

export interface FormLoginContent extends Form {
  login: PropsField;
  password: PropsField;
  corporateKey: PropsFieldMask;
  profile: PropsFieldSelect;
}

export interface FormEditSectionSettings extends Form {
  [name: string]: PropsField | PropsFieldSwitch | PropsFieldSelect;
}

export interface FormEditListElement extends Form {
  ident?: string;
  value: PropsField;
  title: PropsField;
  filteringValues: PropsField;
}

export interface FinalStatus {
  isTerminated: boolean;
  alert: {withSuccess: boolean; title?: string; text?: string};
}

export interface FormSettingTheme extends Form {
  $mainColor: PropsFieldColor;
  $secondaryColor: PropsFieldColor;
  $navbarTitle: PropsFieldColor;
  $darkColor: PropsFieldColor;
  $lightColor: PropsFieldColor;
  $backgroundColor: PropsFieldColor;
  bg_image: PropsField;
  bg_image_manual: PropsField;
  $theme: PropsField;
  $navbarTitleText: PropsField;
  $customerLogoPath: PropsField;
}

export class CForm {
  private form: Form = {};
  constructor(fields?: Form) {
    this.form = fields;
  }

  getForm(): Form {
    return this.form;
  }

  setForm(form: Form) {
    this.form = form;
  }

  getField(fieldName: string): PropsField {
    return this.form[fieldName];
  }

  setFieldValue(fieldName: string, value: any) {
    this.form[fieldName].value = value;
  }

  getFieldValue(fieldName: string): any {
    return this.form[fieldName].value;
  }

  validateForm() {
    for (const property in this.form) {
      if (Object.prototype.hasOwnProperty.call(this.form, property)) {
        // Mettez votre code ici
        if (this.form[property].required) {
          console.log(property);
        }
      }
    }
  }

  valueIsNull(fieldName: string): boolean {
    const value = this.getFieldValue(fieldName);

    return !value || value === undefined || !!(typeof value === 'string' && value.length <= 0);
  }
}

// useForm functional componen
export const useForm = (callback?: any, initialState = {}, checkSpecificsBeforeSubmit?: any) => {
  const [values, setValues] = useState<Form>(initialState);

  const getField = (fieldName: string): PropsField => {
    if (Object.prototype.hasOwnProperty.call(values, fieldName)) {
      return values[fieldName];
    }
    alert(`Internal Error ! the field <${fieldName}> does not exist in the form.`);

    return null;
  };

  function valueIsNull(value: any): boolean {
    if (typeof value === 'number' || typeof value === 'boolean') {
      return value === undefined;
    }
    return !value || value === undefined || (typeof value === 'string' && value.length <= 0);
  }

  // const fieldValueIsNull = (fieldName: string): boolean => {
  //   const value = getFieldValue(fieldName);

  //   return valueIsNull(value);
  // };

  // Forcing to render
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  // onChange
  const onChange = (fieldName: string, value: any, doUpdate = false) => {
    const field = getField(fieldName);

    if (!field) {
      return;
    }

    if (field.invalid && field.required && !valueIsNull(value)) {
      field.invalid = false;
      field.errorText = ``;
    }
    field.value = value;
    values[fieldName] = field;
    // setValues({ ...values, field});
    if (doUpdate) {
      forceUpdate();
    }
  };

  // onSubmit
  const onSubmit = (event?: React.FormEvent<HTMLFormElement>) => {
    if (event) {
      event.preventDefault();
    }

    if (!onValidateForm()) {
      return;
    }
    callback(); // triggering the callback
  };

  const onValidateForm = () => {
    let formIsValid = true;

    for (const property in values) {
      if (Object.prototype.hasOwnProperty.call(values, property)) {
        const field = getField(property);

        if (!field) {
          // eslint-disable-next-line no-continue
          continue;
        }

        if (field.required && valueIsNull(field.value)) {
          formIsValid = false;
          field.invalid = true;
          field.errorText = `<${values[property].label}> is required`;
          setValues({...values, field});
        }

        if (field.maxLength && field.value.length > field.maxLength) {
          formIsValid = false;
          field.invalid = true;
          field.errorText = `<${values[property].label}> is too long`;
          setValues({...values, field});
        }
      }
    }

    if (!formIsValid) {
      return false;
    }

    if (checkSpecificsBeforeSubmit) {
      return checkSpecificsBeforeSubmit();
    }

    return true;
  };

  const updateValues = (newValues: Form) => {
    setValues(newValues);
    forceUpdate();
  };

  return {
    onChange,
    onSubmit,
    onValidateForm,
    updateValues,
    values,
  };
};

/*
label='Login' value={credential.login} valueChange={ (e:string)=> handleValueChange( "login", e)}
*/
