import {useState, createContext} from 'react';


type SidebarContext = {
  sidebarToggle: boolean;
  sidebarHidden: boolean;
  toggleSidebar: () => void;
  closeSidebar: () => void;
  hideSidebar: () => void;
  ShowSidebar: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SidebarContext = createContext<SidebarContext>({} as SidebarContext);

export function SidebarProvider({children}) {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const [sidebarHidden, setSidebarHidden] = useState(false);

  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const closeSidebar = () => {
    setSidebarToggle(false);
  };

  const hideSidebar = () => {
    setSidebarHidden(true);
  };

  const ShowSidebar = () => {
    setSidebarHidden(false);
  };

  return (
    <SidebarContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{sidebarToggle, sidebarHidden, toggleSidebar, closeSidebar, hideSidebar, ShowSidebar}}
    >
      {children}
    </SidebarContext.Provider>
  );
}
