/* eslint-disable no-param-reassign */
import {useNavigate, useLocation} from 'react-router-dom';

class AISRouterClass {
  private routerNavigate = useNavigate();
  private routerLocation = useLocation();

  public getLocation(): string {
    return this.routerLocation.pathname;
  }

  public navigate(url: string) {
    if (url.includes('%MODULE%')) {
      url = url.replace('%MODULE%', this.getModule());
    }
    this.routerNavigate(url);
  }

  public navigateInModule() {
    this.routerNavigate(`/${this.getModule}/`);
  }

  public back() {
    this.routerNavigate(-1);
  }

  public reload() {
    this.routerNavigate(0);
  }

  public getModule() {
    const location = this.getLocation().split('/');

    return location[1];
  }
}

export default AISRouterClass;
