

import {Button, Link} from '@mui/material';
import AISRouterClass from 'src/commons/ais-classes/router/ais-router';
import {ReactNode} from 'react';

type PropsButtons = {
  href?: string;
  label?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  icone?: ReactNode;
  openInNewTab?: boolean;
};

function LinkButton({href, label, icone, onClick, openInNewTab}: PropsButtons) {
  const router = new AISRouterClass();

  const handleClick = () => {
    if (openInNewTab) {
      const win = window.open(href, '_blank');

      if (win) {
        win.focus();
      }
      return;
    }

    if (href) {
      router.navigate(href);
    }

    if (onClick) {
      onClick(null);
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link>
      <Button
        onClick={handleClick}
        sx={{mt: {xs: 2, md: 0}}}
        size="small"
        variant="text"
        endIcon={icone || null}
      >
        {label}
      </Button>
    </Link>
  );
}

export default LinkButton;

