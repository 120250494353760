import {
  Tooltip,
  TooltipProps,
  tooltipClasses,
  styled,
  useTheme,
  Typography,
  Grid,
  Divider,
} from '@mui/material';
import {Link} from 'react-router-dom';
import LogoAirInt from 'src/commons/components/Logo/LogoAirIntServices';
import {CSTE_LABELS} from 'src/commons/constants';
import {AISCacheService} from 'src/commons/services/cacheService';

const LogoWrapper = styled(Link)(
  ({theme}) => `
        color: ${theme.palette.text.primary};
        display: flex;
        text-decoration: none;
        width: 260px;
        margin: 0 auto;
        font-weight: ${theme.typography.fontWeightBold};
`,
);

const TooltipWrapper = styled(({className, ...props}: TooltipProps) => (
  <Tooltip {...props} classes={{popper: className}} />
))(({theme}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.alpha.trueWhite[100],
    color: theme.palette.getContrastText(theme.colors.alpha.trueWhite[100]),
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 'bold',
    borderRadius: theme.general.borderRadiusSm,
    boxShadow: '0 .2rem .8rem rgba(7,9,25,.18), 0 .08rem .15rem rgba(7,9,25,.15)',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.alpha.trueWhite[100],
  },
}));

function Logo({title}) {
  const theme = useTheme();
  const hrefModule = AISCacheService.getBaseModuleHref();

  return (
    <TooltipWrapper title={`${CSTE_LABELS.COMMONS.AIS_SOFTWARE_TITLE} - ${title}`} arrow>
      <LogoWrapper to={hrefModule}>
        <Grid container direction="column" alignItems="center" pr={4}>
          <Grid item>
            <LogoAirInt width={220} height={70} textColor={theme.colors.alpha.trueWhite[100]} />
          </Grid>

          <Grid item mt={1} textAlign="center" sx={{mt: 2}} width={'100%'}>
            <Divider
              sx={{
                mb: theme.spacing(3),

                background: theme.colors.alpha.trueWhite[10],
              }}
            />
            <Typography variant="h4" gutterBottom color={theme.colors.alpha.white[70]}>
              {title}
            </Typography>
          </Grid>
        </Grid>
      </LogoWrapper>
    </TooltipWrapper>
  );
}

export default Logo;
