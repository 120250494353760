import {
  Box,
  Typography,
  styled,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  LinearProgress,
} from '@mui/material';

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
`,
);

const TopWrapper = styled(Box)(
  ({theme}) => `
  display: flex;
  width: 100%;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: ${theme.spacing(6)};
`,
);

function StatusLoading() {
  return (
    <MainContent>
      <TopWrapper>
        <Card sx={{maxWidth: 500}}>
          <CardMedia
            sx={{height: 200, width: 400}}
            image="/static/images/cards/loading-banner.png"
            title="air int laoding photo"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Loading...
            </Typography>
          </CardContent>
          <CardActions>
            <Box sx={{width: '100%'}}>
              <LinearProgress />
            </Box>
          </CardActions>
        </Card>
      </TopWrapper>
    </MainContent>
  );
}

export default StatusLoading;

// StatusLoading.getLayout = function getLayout(page: ReactElement) {
//   return {page};
// };
