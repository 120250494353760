import { Box, Divider, styled } from "@mui/material";

type PropsDividerWrapper = {
  text?: string;
  paddingTop ?: number
  paddingBottom ?: number
};

const DividerWrapperTheme = styled(Divider)(
  ({ theme }) => `
        .MuiDivider-wrapper {
          border-radius: ${theme.general.borderRadiusSm};
          text-transform: none;
          background: ${theme.palette.background.default};
          font-size: ${theme.typography.pxToRem(13)};
          color: ${theme.colors.alpha.black[50]};
        }
  `
);

function DividerWrapper({text, paddingTop, paddingBottom}: PropsDividerWrapper) {
  return (
    <Box sx={{paddingTop: paddingTop || 0, paddingBottom: paddingBottom || 0}}>
      <DividerWrapperTheme>{text}</DividerWrapperTheme>
    </Box>
  );
}

export default DividerWrapper;
