/* eslint-disable @typescript-eslint/no-unused-vars */
import {useContext, useEffect, useRef, useState} from 'react';

import {
  Box,
  alpha,
  Stack,
  lighten,
  Divider,
  IconButton,
  Tooltip,
  styled,
  useTheme,
  Dialog,
  DialogContent,
  DialogTitle,
  Alert,
  Hidden,
} from '@mui/material';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import {SidebarContext} from 'src/commons/contexts/SidebarContext';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import {useAuth} from 'src/commons/contexts/AuthContext';
import FormLogin from 'src/commons/components/Login/Form';
import HeaderButtons from './Buttons';
import HeaderUserbox from './Userbox';
import HeaderMenu from './Menu';

const HeaderWrapper = styled(Box)(
  ({theme}) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 1)};
        right: 0;
        z-index: 6;
        background-color: ${alpha(theme.header.background, 0.95)};
        backdrop-filter: blur(3px);
        position: fixed;
        justify-content: space-between;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            
            width: auto;
        }
`,
);

function Header() {
  const {sidebarToggle, toggleSidebar, sidebarHidden} = useContext(SidebarContext);
  const theme = useTheme();
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const {check} = useAuth();

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    // checkConnection()
    window.location.reload();
  };

  const checkConnection = async () => {
    check(checkFailed);
  };

  const checkFailed = () => {
    if (intervalRef.current !== null) {
      clearInterval(intervalRef.current);
    }
    startConfirmChecked();
  };

  const startConfirmChecked = () => {
    setTimeout(() => {
      checkConnectionConfirm();
    }, 10000);
  };

  const checkConfirmFailed = () => {
    setOpen(true);
  };

  const checkConfirmSuccess = () => {
    intervalRef.current = setInterval(checkConnection, 60000); // 60 secondes
  };

  const checkConnectionConfirm = async () => {
    check(checkConfirmFailed, checkConfirmSuccess);
  };

  
  useEffect(() => {
    // intervalRef.current = setInterval(checkConnection, 60000);
    // return () => {
    //   if (intervalRef.current !== null) {
    //     clearInterval(intervalRef.current);
    //   }
    // };
  }, []);

  return (
    <HeaderWrapper
      display="flex"
      alignItems="center"
      sx={{
        boxShadow:
          theme.palette.mode === 'dark'
            ? `0 1px 0 ${alpha(
                lighten(theme.colors.primary.main, 0.7),
                0.15,
              )}, 0px 2px 8px -3px rgba(0, 0, 0, 0.2), 0px 5px 22px -4px rgba(0, 0, 0, .1)`
            : `0px 2px 8px -3px ${alpha(
                theme.colors.alpha.black[100],
                0.2,
              )}, 0px 5px 22px -4px ${alpha(theme.colors.alpha.black[100], 0.1)}`,
        [theme.breakpoints.up('lg')]: {
          left: sidebarHidden ? '0' : `${theme.sidebar.width}`,
        },
      }}
    >
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        alignItems="center"
        spacing={2}
      >
        <HeaderMenu />
      </Stack>
      <Box display="flex" alignItems="center">
        <Hidden xsDown smDown>
          <HeaderButtons />
          <HeaderUserbox />
        </Hidden>

        <Box
          component="span"
          sx={{
            ml: 2,
            display: {lg: 'none', xs: 'inline-block'},
          }}
        >
          <Tooltip arrow title="Toggle Menu">
            <IconButton color="primary" onClick={toggleSidebar}>
              {!sidebarToggle ? (
                <MenuTwoToneIcon fontSize="large" />
              ) : (
                <CloseTwoToneIcon fontSize="large" />
              )}
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      <Dialog
        open={open}
        maxWidth={'lg'}
        onClose={undefined}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{backgroundColor: theme.palette.primary.dark, color: theme.colors.alpha.white[100]}}
        >
          {'Expired session'}
        </DialogTitle>
        <Divider />
        <DialogContent sx={{width: 800}}>
          <Alert severity="error" sx={{mb: 2}}>
            Your session has expired due to inactivity, <strong>please log in again.</strong>
          </Alert>
          <FormLogin handleLoginSuccess={handleClose} />
        </DialogContent>
      </Dialog>
    </HeaderWrapper>
  );
}

export default Header;
