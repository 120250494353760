import { TextField } from "@mui/material";
import React, { useState } from "react";
import {  PropsFieldMask } from "src/commons/models/commons";
import InputMask from "react-input-mask";

type PropsInputFieldTextMask = {
  field: PropsFieldMask;
  type?: string;
  fullWidth?: boolean;
};
function InputTextMask({field, type, fullWidth = true}: PropsInputFieldTextMask) {
  const [changed, setChanged] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = event.target.value;
    if (field.upperCase) {
      newValue = newValue.toUpperCase();
    }
    field.valueChange(newValue);
    setChanged(!changed);
  };

  return (
    <InputMask
      mask={field.mask}
      value={field.value}
      disabled={field.disabled}
      maskChar=" "
      formatChars={field.formatChars}
      onChange={handleChange}
      autoComplete="off"
      placeHolder="ABC-1234"
    >
      {() => (
        <TextField
          id={field.id}
          fullWidth={fullWidth}
          variant={field.variant ? field.variant : 'outlined'}
          size={field.size ? field.size : 'medium'}
          required={field.required}
          helperText={field.invalid ? field.errorText : ''}
          label={field.label}
          type={type || 'text'}
          name={field.id}
          error={field.invalid}
          autoComplete="off"
          onKeyDown={e => {
            if (field.keyDown) {
              field.keyDown(e);
            }
          }}
          autoFocus={field?.autoFocus || false}
        />
      )}
    </InputMask>
  );
}

export default InputTextMask;
