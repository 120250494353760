
import { TextField } from "@mui/material";
import React, { useState } from "react";
import { PropsField } from "src/commons/models/commons";

type PropsInputFieldText = {
    field: PropsField
    type ?: string
    fullWidth ?:boolean
};
function InputText({field, type, fullWidth = true}: PropsInputFieldText) {
  const [changed, setChanged] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = event.target.value;
    if (field.upperCase) {
      newValue = newValue.toUpperCase();
    }
    field.valueChange(newValue);
    setChanged(!changed);
  };

  return (
    <TextField
      size={field.size ? field.size : 'medium'}
      variant={field.variant ? field.variant : 'outlined'}
      disabled={field.disabled}
      name={field.id}
      error={field.invalid}
      required={field.required}
      inputProps={{maxLength: field.maxLength}}
      type={type || 'text'}
      id={field.id}
      label={field.label}
      fullWidth={fullWidth}
      autoComplete="off"
      helperText={field.invalid ? field.errorText : ''}
      value={field.value}
      onChange={handleChange}
      onKeyDown={e => {
        if (field.keyDown) {
          field.keyDown(e);
        }
      }}
      autoFocus={field?.autoFocus || false}
    />
  );
}


export default InputText;