import {ReactNode, useContext} from 'react';
import {Box, alpha, lighten, useTheme} from '@mui/material';
import {Outlet} from 'react-router-dom';
import {SidebarContext} from 'src/commons/contexts/SidebarContext';
import Header from '../Header';

interface SidebarLayoutProps {
  children?: ReactNode;
}

function SidebarLayout({children}: SidebarLayoutProps) {
  const theme = useTheme();
  const {sidebarHidden} = useContext(SidebarContext);

  return (
    <Box
      sx={{
        flex: 1,
        height: '100%',

        '.MuiPageTitle-wrapper': {
          background:
            theme.palette.mode === 'dark'
              ? theme.colors.alpha.trueWhite[5]
              : theme.colors.alpha.white[50],
          marginBottom: `${theme.spacing(4)}`,
          boxShadow:
            theme.palette.mode === 'dark'
              ? `0 1px 0 ${alpha(
                  lighten(theme.colors.primary.main, 0.7),
                  0.15,
                )}, 0px 2px 4px -3px rgba(0, 0, 0, 0.2), 0px 5px 12px -4px rgba(0, 0, 0, .1)`
              : `0px 2px 4px -3px ${alpha(
                  theme.colors.alpha.black[100],
                  0.1,
                )}, 0px 5px 12px -4px ${alpha(theme.colors.alpha.black[100], 0.05)}`,
        },
      }}
    >
      <Header />

      <Box
        sx={{
          [theme.breakpoints.up('lg')]: {
            display: sidebarHidden ? 'none' : 'inline-block',
          },
        }}
      >
        {children}
      </Box>

      <Box
        sx={{
          position: 'relative',
          zIndex: 5,
          display: 'block',
          flex: 1,
          pt: '70px',
          [theme.breakpoints.up('lg')]: {
            ml: sidebarHidden ? '0' : `${theme.sidebar.width}`,
            pt: sidebarHidden ? '75px' : '60px',
          },
        }}
      >
        <Box display="block">
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}

export default SidebarLayout;
