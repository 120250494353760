import {useRoutes} from 'react-router-dom';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import {CssBaseline} from '@mui/material';

import router from './commons/ais-classes/router/router';
import ThemeProvider from './theme/ThemeProvider';
import {AuthProvider, ProtectRoute} from './commons/contexts/AuthContext';
import {TimerProvider} from './commons/contexts/TimersContext';

function App() {
  const content = useRoutes(router);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        <AuthProvider>
          <TimerProvider>
            <ProtectRoute>{content}</ProtectRoute>
          </TimerProvider>
        </AuthProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
